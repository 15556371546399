import { TechEdit } from './TechEdit'

import Computer from "@material-ui/icons/Computer";
import { TechList } from './TechList'

const options = {
    edit: TechEdit,
    list: TechList,
    icon: Computer
}

export default options