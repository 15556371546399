import React from "react";
import { Toolbar } from "react-admin";
import AboutSaveButton from "./AboutSaveButton";

const AboutToolbar = props => (
  <Toolbar {...props}>
    <AboutSaveButton
      label="Zapisz"
      redirect={props.redirect}
      submitOnEnter={false}
    />
  </Toolbar>
);

export default AboutToolbar;
