const local = {
    client: {
        URL: "http://localhost:3000"
    },
    apiGateway: {
        URL: "http://localhost:5002/api"
    },
};

const dev = {
    client: {
        URL: "https://inwill-dev.vercel.app/"
    },
    apiGateway: {
        URL: "https://admin-api-dev.inwill.ovh/api"
    },
};

const prod = {
    client: {
        URL: "https://www.inwill.pl/"
    },
    apiGateway: {
        URL: "https://admin-api.inwill.ovh/api"
    },
};

const config =
    process.env.REACT_APP_STAGE === "prod"
        ? prod
        : process.env.REACT_APP_STAGE === "dev"
            ? dev
            : local;

export default config;
