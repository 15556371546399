import React from "react";
import {
    TextInput,
    TabbedForm,
    FormTab,
    ImageField,
    ImageInput,
    Edit,
} from "react-admin";
import RichTextInputWithExtendedToolbar from "../shared/components/RichTextInputWithExtendedToolbar";
import Divider from "../shared/components/CustomDivider";
import AboutToolbar from "./components/AboutToolbar";

export const AboutEdit = (props) => (
    <Edit undoable={false} {...props}>
        <TabbedForm
            toolbar={<AboutToolbar redirect="edit" />}
            validate={validateAbout}
        >
            <FormTab label="Informacje">
                <h3>PL</h3>
                <TextInput label="Tytuł" fullWidth source="pl.title" />
                <RichTextInputWithExtendedToolbar
                    label="Długi opis"
                    source="pl.description"
                />
                <Divider />
                <h3>EN</h3>
                <TextInput label="Tytuł" fullWidth source="en.title" />
                <RichTextInputWithExtendedToolbar
                    label="Długi opis"
                    source="en.description"
                />
                <Divider />
            </FormTab>
            <FormTab label="Zdjęcia - PL">
                <ImageInput source="pl.mainImage" label="Główne zdjęcie" accept="image/*">
                    <ImageField source="path" />
                </ImageInput>
                <ImageInput
                    source="pl.images"
                    label="Pozostałe zdjęcia (Uwaga: W przypadku wgrywania wielu zdjęć jednocześnie ich kolejność będzie równoznaczna z kolejnością w sekcji o nas!)"
                    accept="image/*"
                    multiple
                >
                    <ImageField source="path" />
                </ImageInput>
            </FormTab>
            <FormTab label="Zdjęcia - EN">
                <ImageInput source="en.mainImage" label="Główne zdjęcie" accept="image/*">
                    <ImageField source="path" />
                </ImageInput>
                <ImageInput
                    source="en.images"
                    label="Pozostałe zdjęcia (Uwaga: W przypadku wgrywania wielu zdjęć jednocześnie ich kolejność będzie równoznaczna z kolejnością w sekcji o nas!)"
                    accept="image/*"
                    multiple
                >
                    <ImageField source="path" />
                </ImageInput>
            </FormTab>
        </TabbedForm>
    </Edit>
);

const validateAbout = async (values) => {
    const errors = {
        pl: {},
        en: {}
    };

    if (!values.pl?.title) {
        errors.pl.title = ["Tytuł jest wymagany"];
    } else {
        if (values.pl?.title.length > 100) {
            errors.pl.title = ["Tytuł może posiadać maksymalnie 100 znaków"];
        }
    }
    if (!values.pl?.description) {
        errors.pl.description = ["Opis jest wymagany"];
    }
    if (
        values.pl?.mainImage &&
        values.pl?.mainImage.rawFile &&
        values.pl?.mainImage.rawFile.size > 20000000
    ) {
        errors.pl.mainImage = ["Zdjęcia mogą posiadać rozmiar maksymalnie 20mb."];
    }

    if (values.pl?.images) {
        await values.pl.images.map((image) => {
            if (image.rawFile && image.rawFile.size > 20000000) {
                return (errors.images = [
                    "Zdjęcia mogą posiadać rozmiar maksymalnie 20mb.",
                ]);
            }
            return true;
        });
    }


    if (!values.en?.title) {
        errors.en.title = ["Tytuł jest wymagany"];
    } else {
        if (values.en?.title.length > 100) {
            errors.en.title = ["Tytuł może posiadać maksymalnie 100 znaków"];
        }
    }
    if (!values.en?.description) {
        errors.en.description = ["Opis jest wymagany"];
    }
    if (
        values.en?.mainImage &&
        values.en?.mainImage.rawFile &&
        values.en?.mainImage.rawFile.size > 20000000
    ) {
        errors.en.mainImage = ["Zdjęcia mogą posiadać rozmiar maksymalnie 20mb."];
    }

    if (values.en?.images) {
        await values.en.images.map((image) => {
            if (image.rawFile && image.rawFile.size > 20000000) {
                return (errors.images = [
                    "Zdjęcia mogą posiadać rozmiar maksymalnie 20mb.",
                ]);
            }
            return true;
        });
    }

    return errors;
};
