import { ContactEdit } from './ContactEdit'

import ContactMail from "@material-ui/icons/ContactMail";
import { ContactList } from './ContactList'

const options = {
    edit: ContactEdit,
    list: ContactList,
    icon: ContactMail
}

export default options