import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    media: {
        height: "237px",
        width: "709px",
        display: "block",
        "margin-left": "auto",
        "margin-right": "auto"
    },
    text: {
        "text-align": "center"
    }
});

const Welcome = () => {
    const classes = useStyles();
    return (
        <Card>
            <CardContent className={classes.text}>
                <Typography variant="h5" component="h2">
                    Witamy w panelu administratora strony internetowej - Inwill!
        </Typography>
            </CardContent>
        </Card>
    );
};

export default Welcome;
