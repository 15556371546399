import { HttpError } from "react-admin";

export const fetchJson = async (url, options = {}) => {
    const requestHeaders =
        options.headers ||
        new Headers({
            Accept: "application/json"
        });
    if (
        !requestHeaders.has("Content-Type") &&
        !(options && options.body && options.body instanceof FormData)
    ) {
        requestHeaders.set("Content-Type", "application/json");
    }
    if (options.user && options.user.authenticated && options.user.token) {
        requestHeaders.set("Authorization", options.user.token);
    }
    const response = await fetch(url, { ...options, headers: requestHeaders });
    const text = await response.text();
    const o = {
        status: response.status,
        headers: response.headers,
        body: text
    };
    let status = o.status,
        headers = o.headers,
        body = o.body;
    let json;
    try {
        json = JSON.parse(body);
    } catch (e) {
        // not json, no big deal
    }
    if (status < 200 || status >= 300) {
        if (status === 422 || status === 500) {
            return Promise.reject(
                new HttpError("Wystąpił nieoczekiwany błąd techniczny.", status, json)
            );
        }

        return Promise.reject(
            new HttpError(
                (json && json.errors && json.errors.join(" | ")) ||
                "Wystąpił nieoczekiwany błąd.",
                status,
                json
            )
        );
    }
    return Promise.resolve({
        status: status,
        headers: headers,
        body: body,
        json: json
    });
};
