import React, { useCallback } from "react";
import { useForm } from "react-final-form";
import { SaveButton } from "react-admin";
import FileToBase64Converter from "../../shared/components/FileToBase64Converter";

const NewsSaveButton = ({ handleSubmitWithRedirect, redirect, ...props }) => {
  const form = useForm();

  const setupMainImagePL = () => {
    form.change("pl.mainImagePath", null);
    let mainImage = form.getFieldState("pl.mainImage").value;
    if (mainImage) {
      let newImages = [mainImage].filter(p => p.rawFile instanceof File);
      let formerImages = [mainImage].filter(p => !(p.rawFile instanceof File));

      if (newImages && newImages.length > 0) {
        Promise.all(newImages.map(FileToBase64Converter))
          .then(base64Images =>
            base64Images.map(result => ({
              base64File: result.file64
            }))
          )
          .then(transformedNewImages => {
            if (transformedNewImages) {
              form.change(
                "pl.mainImageBase64File",
                transformedNewImages[0].base64File
              );
            }
            setupImagesEN()
          });
      } else {
        if (
          formerImages &&
          formerImages.length > 0 &&
          formerImages[0] &&
          formerImages[0].length > 0
        ) {
          form.change("pl.mainImagePath", formerImages[0][0].path);
          setupImagesEN()
        } else {
          setupImagesEN()
        }
      }
    } else {
      setupImagesEN()
    }
  };

  const setupImagesPL = () => {
    let images = form.getFieldState("pl.images").value;
    if (images) {
      let newImages = images.filter(p => p.rawFile instanceof File);
      let formerImages = images.filter(p => !(p.rawFile instanceof File));

      Promise.all(newImages.map(FileToBase64Converter))
        .then(base64Images =>
          base64Images.map(result => ({
            base64File: result.file64
          }))
        )
        .then(transformedNewImages => {
          form.change("pl.newImages", transformedNewImages);
          form.change("pl.formerImages", formerImages);
          setupMainImagePL();
        });
    } else {
      setupMainImagePL();
    }
  };

  const setupMainImageEN = () => {
    form.change("en.mainImagePath", null);
    let mainImage = form.getFieldState("en.mainImage").value;
    if (mainImage) {
      let newImages = [mainImage].filter(p => p.rawFile instanceof File);
      let formerImages = [mainImage].filter(p => !(p.rawFile instanceof File));

      if (newImages && newImages.length > 0) {
        Promise.all(newImages.map(FileToBase64Converter))
          .then(base64Images =>
            base64Images.map(result => ({
              base64File: result.file64
            }))
          )
          .then(transformedNewImages => {
            if (transformedNewImages) {
              form.change(
                "en.mainImageBase64File",
                transformedNewImages[0].base64File
              );
            }
            handleSubmitWithRedirect(redirect);
          });
      } else {
        if (
          formerImages &&
          formerImages.length > 0 &&
          formerImages[0] &&
          formerImages[0].length > 0
        ) {
          form.change("en.mainImagePath", formerImages[0][0].path);
          handleSubmitWithRedirect(redirect);
        } else {
          handleSubmitWithRedirect(redirect);
        }
      }
    } else {
      handleSubmitWithRedirect(redirect);
    }
  };

  const setupImagesEN = () => {
    let images = form.getFieldState("en.images").value;
    if (images) {
      let newImages = images.filter(p => p.rawFile instanceof File);
      let formerImages = images.filter(p => !(p.rawFile instanceof File));

      Promise.all(newImages.map(FileToBase64Converter))
        .then(base64Images =>
          base64Images.map(result => ({
            base64File: result.file64
          }))
        )
        .then(transformedNewImages => {
          form.change("en.newImages", transformedNewImages);
          form.change("en.formerImages", formerImages);
          setupMainImageEN();
        });
    } else {
      setupMainImageEN();
    }
  };

  const handleClick = useCallback(() => {
    setupImagesPL();

    // eslint-disable-next-line
  }, [form]);

  return (
    <SaveButton
      {...props}
      handleSubmitWithRedirect={handleClick}
      redirect={redirect}
    />
  );
};

export default NewsSaveButton;
