import { GrantsList } from "./GrantsList";
import { GrantsEdit } from "./GrantsEdit";
import { GrantsCreate } from "./GrantsCreate";
import Business from "@material-ui/icons/Business";

const options = {
  list: GrantsList,
  edit: GrantsEdit,
  create: GrantsCreate,
  icon: Business
}

export default options;