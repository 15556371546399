import React from "react";
import { CreateButton } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";

const ListActions = ({
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
  hideCreate
}) => {
  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button"
        })}
      {!hideCreate && <CreateButton />}
    </Toolbar>
  );
};

export default ListActions;
