import { MainList } from './MainList'
import { MainEdit } from './MainEdit'
import { MainCreate } from './MainCreate'

import Home from "@material-ui/icons/Home";

const options = {
    list: MainList,
    edit: MainEdit,
    create: MainCreate,
    icon: Home
}

export default options