import React from "react";

import Welcome from "./Welcome";

const styles = {
    flex: { display: "flex" },
    flexColumn: { display: "flex", flexDirection: "column" },
    leftCol: { flex: 1, marginRight: "1em" },
    rightCol: { flex: 1, marginLeft: "1em" },
    singleCol: { marginTop: "2em", marginBottom: "2em" }
};

const Dashboard = () => {
    return (
        <div>
            <div style={styles.flexColumn}>
                <div style={{ marginBottom: "2em" }}>
                    <Welcome />
                </div>
            </div>
        </div>
    )
}

export default Dashboard;