import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  EditButton,
  DeleteButton
} from "react-admin";
import ListActions from "../shared/components/ListActions";

export const NewsList = props => (
  <List
    title={"Lista aktualności"}
    {...props}
    bulkActionButtons={false}
    actions={<ListActions />}
    sort={{ field: 'id', order: 'ASC' }}
  >
    <Datagrid>
      <NumberField label="Id" source="id" sortable={false} />
      <DateField label="Utworzono" source="created" showTime sortable={false} />
      <DateField label="Zmodyfikowano" source="modified" showTime sortable={false} />
      <TextField label="Tytuł PL" source="translations.pl.title" sortable={false} />
      <TextField label="Tytuł EN" source="translations.en.title" sortable={false} />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);
