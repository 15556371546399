import React from "react";
import { Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import polishMessages from "ra-language-polish";
import { createMuiTheme } from "@material-ui/core/styles";

import news from "./news";
import mainpage from "./mainpage"
import grants from "./grants"
import about from './about'
import tech from './tech'
import contact from './contact'

import { dashboard } from "./dashboard";
import dataProvider from "./shared/DataProvider";
import authProvider from "./shared/AuthProvider";

const i18nProvider = polyglotI18nProvider(() => polishMessages, "pl");
const theme = createMuiTheme({
    palette: {
        secondary: {
            main: "#132750"
        }
    }
});

const App = () => (
    <Admin
        theme={theme}
        dashboard={dashboard}
        i18nProvider={i18nProvider}
        dataProvider={dataProvider}
        authProvider={authProvider}
    >
        <Resource name="home-page-sections" options={{ label: "Strona główna" }} {...mainpage} />
        <Resource name="news" options={{ label: "Aktualności" }} {...news} />
        <Resource name="grants" options={{ label: "Granty" }} {...grants} />
        <Resource name="about" options={{ label: 'O nas' }} {...about} />
        <Resource name="technology" options={{ label: 'O technologii' }} {...tech} />
        <Resource name="contact" options={{ label: 'Kontakt' }} {...contact} />
    </Admin>
)

export default App;