import React from "react";
import { Toolbar } from "react-admin";
import MainSaveButton from "./MainSaveButton";

const MainToolbar = props => (
  <Toolbar {...props}>
    <MainSaveButton
      label="Zapisz"
      redirect={props.redirect}
      submitOnEnter={false}
    />
  </Toolbar>
);

export default MainToolbar;
