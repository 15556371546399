import React, { useCallback } from "react";
import { useForm } from "react-final-form";
import { SaveButton } from "react-admin";
import FileToBase64Converter from "../../shared/components/FileToBase64Converter";

const MainSaveButton = ({ handleSubmitWithRedirect, redirect, ...props }) => {
  const form = useForm();

  const setupMainImagePL = () => {
    form.change("pl.mainImagePath", null);
    let mainImage = form.getFieldState("pl.mainImage")?.value;
    if (mainImage) {
      let newImages = [mainImage].filter(p => p.rawFile instanceof File);
      let formerImages = [mainImage].filter(p => !(p.rawFile instanceof File));

      if (newImages && newImages.length > 0) {
        Promise.all(newImages.map(FileToBase64Converter))
          .then(base64Images =>
            base64Images.map(result => ({
              base64File: result.file64
            }))
          )
          .then(transformedNewImages => {
            if (transformedNewImages) {
              form.change(
                "pl.mainImageBase64File",
                transformedNewImages[0].base64File
              );
            }
            setupMainImageEN()
          });
      } else {
        if (
          formerImages &&
          formerImages.length > 0 &&
          formerImages[0] &&
          formerImages[0].length > 0
        ) {
          form.change("pl.mainImagePath", formerImages[0][0].path);
          setupMainImageEN()
        } else {
          setupMainImageEN()
        }
      }
    } else {
      setupMainImageEN()
    }
  };

  const setupMainImageEN = () => {
    form.change("en.mainImagePath", null);
    let mainImage = form.getFieldState("en.mainImage")?.value;
    if (mainImage) {
      let newImages = [mainImage].filter(p => p.rawFile instanceof File);
      let formerImages = [mainImage].filter(p => !(p.rawFile instanceof File));

      if (newImages && newImages.length > 0) {
        Promise.all(newImages.map(FileToBase64Converter))
          .then(base64Images =>
            base64Images.map(result => ({
              base64File: result.file64
            }))
          )
          .then(transformedNewImages => {
            if (transformedNewImages) {
              form.change(
                "en.mainImageBase64File",
                transformedNewImages[0].base64File
              );
            }
            setupMainImageJP()
          });
      } else {
        if (
          formerImages &&
          formerImages.length > 0 &&
          formerImages[0] &&
          formerImages[0].length > 0
        ) {
          form.change("en.mainImagePath", formerImages[0][0].path);
          setupMainImageJP()
        } else {
          setupMainImageJP()
        }
      }
    } else {
      setupMainImageJP()
    }
  };

  const setupMainImageJP = () => {
    form.change("jp.mainImagePath", null);
    let mainImage = form.getFieldState("jp.mainImage")?.value;
    if (mainImage) {
      let newImages = [mainImage].filter(p => p.rawFile instanceof File);
      let formerImages = [mainImage].filter(p => !(p.rawFile instanceof File));

      if (newImages && newImages.length > 0) {
        Promise.all(newImages.map(FileToBase64Converter))
          .then(base64Images =>
            base64Images.map(result => ({
              base64File: result.file64
            }))
          )
          .then(transformedNewImages => {
            if (transformedNewImages) {
              form.change(
                "jp.mainImageBase64File",
                transformedNewImages[0].base64File
              );
            }
            setupMainImageNO()
          });
      } else {
        if (
          formerImages &&
          formerImages.length > 0 &&
          formerImages[0] &&
          formerImages[0].length > 0
        ) {
          form.change("jp.mainImagePath", formerImages[0][0].path);
          setupMainImageNO()
        } else {
          setupMainImageNO()
        }
      }
    } else {
      setupMainImageNO()
    }
  };

  const setupMainImageNO = () => {
    form.change("no.mainImagePath", null);
    let mainImage = form.getFieldState("no.mainImage")?.value;
    if (mainImage) {
      let newImages = [mainImage].filter(p => p.rawFile instanceof File);
      let formerImages = [mainImage].filter(p => !(p.rawFile instanceof File));

      if (newImages && newImages.length > 0) {
        Promise.all(newImages.map(FileToBase64Converter))
          .then(base64Images =>
            base64Images.map(result => ({
              base64File: result.file64
            }))
          )
          .then(transformedNewImages => {
            if (transformedNewImages) {
              form.change(
                "no.mainImageBase64File",
                transformedNewImages[0].base64File
              );
            }
            handleSubmitWithRedirect(redirect);
          });
      } else {
        if (
          formerImages &&
          formerImages.length > 0 &&
          formerImages[0] &&
          formerImages[0].length > 0
        ) {
          form.change("en.mainImagePath", formerImages[0][0].path);
          handleSubmitWithRedirect(redirect);
        } else {
          handleSubmitWithRedirect(redirect);
        }
      }
    } else {
      handleSubmitWithRedirect(redirect);
    }
  };

  const setupImages = () => {
    setupMainImagePL();
  };

  const handleClick = useCallback(() => {
    setupImages();

    // eslint-disable-next-line
  }, [form]);

  return (
    <SaveButton
      {...props}
      handleSubmitWithRedirect={handleClick}
      redirect={redirect}
    />
  );
};

export default MainSaveButton;
