import React from "react";
import { Toolbar } from "react-admin";
import TechSaveButton from "./TechSaveButton";

const TechToolbar = props => (
  <Toolbar {...props}>
    <TechSaveButton
      label="Zapisz"
      redirect={props.redirect}
      submitOnEnter={false}
    />
  </Toolbar>
);

export default TechToolbar;
