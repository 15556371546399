import React from "react";
import {
    List,
    Datagrid,
    DateField,
    NumberField,
    EditButton,
} from "react-admin";
import ListActions from "../shared/components/ListActions";


export const ContactList = props => (
    <List
        title={"Kontakt"}
        {...props}
        bulkActionButtons={false}
        actions={<ListActions hideCreate={true} />}
        sort={{ field: 'id', order: 'ASC' }}
    >
        <Datagrid>
            <NumberField label="Id" source="id" sortable={false} />
            <DateField label="Utworzono" source="created" showTime sortable={false} />
            <DateField label="Zmodyfikowano" source="modified" showTime sortable={false} />
            <EditButton />
        </Datagrid>
    </List>
);
