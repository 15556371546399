import React from "react";
import {
    TabbedForm,
    FormTab,
    Edit,
} from "react-admin";
import RichTextInputWithExtendedToolbar from "../shared/components/RichTextInputWithExtendedToolbar";
import Divider from "../shared/components/CustomDivider";
import ContactToolbar from "./components/ContactToolbar";

export const ContactEdit = (props) => (
    <Edit undoable={false} {...props}>
        <TabbedForm
            toolbar={<ContactToolbar redirect="edit" />}
            validate={validateContact}
        >
            <FormTab label="Informacje">
                <h3>PL</h3>
                <RichTextInputWithExtendedToolbar
                    label="Adres"
                    source="pl.address"
                />
                <Divider />
                <h3>EN</h3>
                <RichTextInputWithExtendedToolbar
                    label="Adres"
                    source="en.address"
                />
                <Divider />
            </FormTab>
        </TabbedForm>
    </Edit>
);

const validateContact = async (values) => {
    const errors = {
        pl: {},
        en: {}
    };

    if (!values.pl?.address) {
        errors.pl.address = ["Adres jest wymagany"];
    }

    if (!values.en?.address) {
        errors.en.address = ["Adres jest wymagany"];
    }

    return errors;
};
