import { NewsList } from "./NewsList";
import { NewsEdit } from "./NewsEdit";
import { NewsCreate } from "./NewsCreate";
import Note from "@material-ui/icons/Note";

const options = {
  list: NewsList,
  edit: NewsEdit,
  create: NewsCreate,
  icon: Note
}

export default options;