import React, { useCallback } from "react";
import { useForm } from "react-final-form";
import { SaveButton } from "react-admin";

const ContactSaveButton = ({ handleSubmitWithRedirect, redirect, ...props }) => {
  const form = useForm();

  const handleClick = useCallback(() => {
    handleSubmitWithRedirect(redirect);

    // eslint-disable-next-line
  }, [form]);

  return (
    <SaveButton
      {...props}
      handleSubmitWithRedirect={handleClick}
      redirect={redirect}
    />
  );
};

export default ContactSaveButton;
