import { AboutEdit } from './AboutEdit'

import Info from "@material-ui/icons/Info";
import { AboutList } from './AboutList'

const options = {
    edit: AboutEdit,
    list: AboutList,
    icon: Info
}

export default options