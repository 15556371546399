import React from "react";
import {
    TextInput,
    TabbedForm,
    FormTab,
    ImageField,
    ImageInput,
    NumberInput,
    Edit,
    SelectInput
} from "react-admin";

import RichTextInputWithExtendedToolbar from "../shared/components/RichTextInputWithExtendedToolbar";
import Divider from "../shared/components/CustomDivider";
import MainToolbar from './components/MainToolbar';

export const MainEdit = (props) => (
    <Edit undoable={false} {...props}>
        <TabbedForm
            toolbar={<MainToolbar redirect="edit" />}
            validate={validateMain}
        >
            <FormTab label="Informacje">
                <h3>Ogólne</h3>
                <NumberInput label="Kolejność" source="sortOrder" />
                <SelectInput source="layoutType" label="Szablon" choices={[
                    { id: 'base', name: 'Podstawowy' },
                    { id: 'photoLeft', name: 'Zdjęcie z lewej' },
                    { id: 'photoRight', name: 'Zdjęcie z prawej' },
                ]} />

                <h3>PL</h3>
                <TextInput label="Tytuł" fullWidth source="pl.title" />
                <ImageInput source="pl.mainImage" label="Główne zdjęcie" accept="image/*">
                    <ImageField source="path" />
                </ImageInput>
                <TextInput label="Tekst przycisku" fullWidth source="pl.buttonText" />
                <TextInput label="Link przycisku" fullWidth source="pl.buttonLink" />
                <RichTextInputWithExtendedToolbar
                    label="Opis"
                    source="pl.description"
                />
                <Divider />

                <h3>EN</h3>
                <TextInput label="Tytuł" fullWidth source="en.title" />
                <ImageInput source="en.mainImage" label="Główne zdjęcie" accept="image/*">
                    <ImageField source="path" />
                </ImageInput>
                <TextInput label="Tekst przycisku" fullWidth source="en.buttonText" />
                <TextInput label="Link przycisku" fullWidth source="en.buttonLink" />
                <RichTextInputWithExtendedToolbar
                    label="Opis"
                    source="en.description"
                />
                <Divider />
                <h3>NO</h3>
                <TextInput label="Tytuł" fullWidth source="no.title" />
                <ImageInput source="no.mainImage" label="Główne zdjęcie" accept="image/*">
                    <ImageField source="path" />
                </ImageInput>
                <TextInput label="Tekst przycisku" fullWidth source="no.buttonText" />
                <TextInput label="Link przycisku" fullWidth source="no.buttonLink" />
                <RichTextInputWithExtendedToolbar
                    label="Opis"
                    source="no.description"
                />

                <Divider />
                <h3>JP</h3>
                <TextInput label="Tytuł" fullWidth source="jp.title" />
                <ImageInput source="jp.mainImage" label="Główne zdjęcie" accept="image/*">
                    <ImageField source="path" />
                </ImageInput>
                <TextInput label="Tekst przycisku" fullWidth source="jp.buttonText" />
                <TextInput label="Link przycisku" fullWidth source="jp.buttonLink" />
                <RichTextInputWithExtendedToolbar
                    label="Opis"
                    source="jp.description"
                />
            </FormTab>
        </TabbedForm>
    </Edit>
)
const validateMain = async (values) => {
    const errors = {
        pl: {},
        en: {},
        no: {},
        jp: {}
    };

    if (!values.pl?.title) {
        errors.pl.title = ["Tytuł jest wymagany"];
    } else {
        if (values.pl?.title.length > 100) {
            errors.pl.title = ["Tytuł może posiadać maksymalnie 100 znaków"];
        }
    }
    if (
        values.pl?.mainImage &&
        values.pl?.mainImage.rawFile &&
        values.pl?.mainImage.rawFile.size > 20000000
    ) {
        errors.pl.mainImage = ["Zdjęcia mogą posiadać rozmiar maksymalnie 20mb."];
    }

    if (!values.en?.title) {
        errors.en.title = ["Tytuł jest wymagany"];
    } else {
        if (values.en?.title.length > 100) {
            errors.en.title = ["Tytuł może posiadać maksymalnie 100 znaków"];
        }
    }
    if (
        values.en?.mainImage &&
        values.en?.mainImage.rawFile &&
        values.en?.mainImage.rawFile.size > 20000000
    ) {
        errors.en.mainImage = ["Zdjęcia mogą posiadać rozmiar maksymalnie 20mb."];
    }

    if (!values.no?.title) {
        errors.no.title = ["Tytuł jest wymagany"];
    } else {
        if (values.no?.title.length > 100) {
            errors.no.title = ["Tytuł może posiadać maksymalnie 100 znaków"];
        }
    }
    if (
        values.no?.mainImage &&
        values.no?.mainImage.rawFile &&
        values.no?.mainImage.rawFile.size > 20000000
    ) {
        errors.no.mainImage = ["Zdjęcia mogą posiadać rozmiar maksymalnie 20mb."];
    }

    if (!values.jp?.title) {
        errors.jp.title = ["Tytuł jest wymagany"];
    } else {
        if (values.jp?.title.length > 100) {
            errors.jp.title = ["Tytuł może posiadać maksymalnie 100 znaków"];
        }
    }
    if (
        values.jp?.mainImage &&
        values.jp?.mainImage.rawFile &&
        values.jp?.mainImage.rawFile.size > 20000000
    ) {
        errors.jp.mainImage = ["Zdjęcia mogą posiadać rozmiar maksymalnie 20mb."];
    }

    return errors;
}